import { css } from 'third-party';

const size = {
  mobile: '320px',
  tablet: '768px',
  laptop: '1024px',
  desktop: '2560px',
};

export const mobile = (inner: any) => css`
  @media screen and (max-width: 480px) {
    ${inner};
  }
`;

export const tablet = (inner: any) => css`
  @media (max-width: ${size.tablet}) {
    ${inner};
  }
`;

export const desktop = (inner: any) => css`
  @media (max-width: ${size.desktop}) {
    ${inner};
  }
`;

export const laptop = (inner: any) => css`
  @media (max-width: ${size.laptop}) {
    ${inner};
  }
`;

export const visibleForTablet = () =>
  tablet(css`
    display: none;
  `);
