import { useMemo } from 'react';

import { bindActionCreators, useDispatch } from 'third-party';

import { userAuthEndpoint, userInfoEndpoint } from 'constants/endpoints';

import { saveUserToken } from 'utils/auth';
import { httpMethod, simplifyBuilder } from 'utils/sra';

import { APIUser } from 'types/api';

export type AppState = {
  user: APIUser | null;
  userInfoPending: boolean;
  isAppLoaded: boolean;
};

export const appState: AppState = {
  user: null,
  userInfoPending: false,
  isAppLoaded: false,
};

const builder = simplifyBuilder(appState, {});

export const setAppIsLoaded = builder.createReduxAction(
  (isAppLoaded: boolean) => ({
    name: 'setUser',
    updater: () => ({
      isAppLoaded: isAppLoaded,
    }),
  }),
);

export const setUser = builder.createReduxAction(
  (user: APIUser | undefined) => ({
    name: 'setUser',
    updater: () => ({
      user: user,
    }),
  }),
);

export const userLogOut = builder.createReduxAction(() => ({
  name: 'clearUser',
  updater: () => ({
    user: null,
  }),
}));

const userLogIn = builder.createServerAction(
  (email: string, password: string) => ({
    name: 'userLoginIn',
    url: userAuthEndpoint(),
    method: httpMethod.post,
    body: { email, password },
    onRequest: () => ({
      userLoginInErrorText: '',
    }),
    onSuccess: (state: AppState, payload: string) => {
      saveUserToken(payload);
    },
  }),
);

const userInfo = builder.createServerAction(() => ({
  name: 'userInfo',
  url: userInfoEndpoint(),
  method: httpMethod.get,
  onSuccess: (state: AppState, payload: APIUser) => ({
    user: payload,
  }),
}));

export const useApp = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          setUser,
          userInfo,
          setAppIsLoaded,
          userLogOut,
          userLogIn,
        },
        dispatch,
      ),
    [dispatch],
  );
};

export const appReducer = builder.getReducers();
