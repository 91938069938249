import React from 'react';

import 'i18n';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ReactDOM from 'react-dom/client';

import { RootContainer } from 'features/Root/Root';

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(<RootContainer />);
