import React from 'react';

import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';

import { emptyStubUrl, paymentsListUrl } from 'constants/url';

export const MainMenu = [
  {
    subHeader: 'Core level',
    items: [
      {
        url: () => emptyStubUrl('dashboard'),
        text: 'Dashboard',
        icon: <BarChartRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => paymentsListUrl(),
        text: 'Payments',
        icon: <CreditCardRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => emptyStubUrl('users'),
        text: 'Users',
        icon: <PeopleOutlineRoundedIcon color="primary" fontSize="small" />,
      },
    ],
  },
  {
    subHeader: 'Organization',
    items: [
      {
        url: () => emptyStubUrl('organization-payments'),
        text: 'Payments',
        icon: <ReceiptRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => emptyStubUrl('payers'),
        text: 'Payers',
        icon: <PaymentsRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => emptyStubUrl('banks'),
        text: 'Banks',
        icon: <AccountBalanceRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => emptyStubUrl('members'),
        text: 'Members',
        icon: <PeopleAltRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => emptyStubUrl('user-activity'),
        text: 'User activity',
        icon: <ManageSearchRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => emptyStubUrl('settings'),
        text: 'Settings',
        icon: <SettingsRoundedIcon color="primary" fontSize="small" />,
      },
    ],
  },
];
