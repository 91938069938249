import React, { useCallback, useMemo, useState } from 'react';

import styled, { useTheme } from 'styled-components';

import SettingsIcon from '@mui/icons-material/Settings';

import {
  Button,
  FormControlLabel,
  Menu,
  Stack,
  Switch,
  Typography,
} from 'components';

import { TableColumn } from './types';

import { useTranslation } from 'third-party';

const Wrapper = styled.div`
  padding: 0.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

const Label = styled(Typography)`
  font-size: 1.6rem;
  font-weight: 400;
  margin-left: 1rem;
`;

type Props<ItemT> = {
  columns: TableColumn<ItemT>[];
  onColumnVisibilityChanged: (columnId: string, value: boolean) => void;
};

export const TableSettings = <ItemT,>({
  columns,
  onColumnVisibilityChanged,
}: Props<ItemT>) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const canBeHiddenColumns = useMemo(() => {
    return columns.filter(it => !it.cannotBeHidden);
  }, [columns]);

  const [popupAnchorEl, setPopupAnchorEl] = useState<null | Element>();

  const closePopup = useCallback(() => {
    setPopupAnchorEl(null);
  }, []);

  const onTriggerButtonClicked = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setPopupAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Button onClick={onTriggerButtonClicked}>
        <SettingsIcon />
      </Button>
      <Menu
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={popupAnchorEl}
        open={!!popupAnchorEl}
        onClose={closePopup}
        sx={{
          marginTop: '2rem',
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {},
        }}
      >
        <Wrapper>
          <Typography
            variant="h3"
            textTransform="uppercase"
            color={theme.custom.palette.subTittle}
          >
            {t('table.columnVisibility')}
          </Typography>
          {canBeHiddenColumns.map(it => (
            <Stack
              key={it.dataId.toString()}
              direction="row"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Switch
                    size="small"
                    checked={!it.hidden}
                    onChange={event =>
                      onColumnVisibilityChanged(
                        it.dataId.toString(),
                        event.target.checked,
                      )
                    }
                  />
                }
                label={
                  <Label>
                    {
                      //@ts-ignore
                      t(it.label)
                    }
                  </Label>
                }
              />
            </Stack>
          ))}
        </Wrapper>
      </Menu>
    </>
  );
};
