import React from 'react';

import {
  AppContainer,
  BanksCreateEditForm,
  BanksList,
  EmptyStub,
  Login,
  MainView,
  PaymentsList,
} from 'features';

import { Route } from 'third-party';

import {
  appUrl,
  bankCreateUrl,
  bankEditUrl,
  bankListUrl,
  emptyStubUrl,
  loginUrl,
  paymentsListUrl,
} from 'constants/url';

export const useRoutes = () => {
  return (
    <Route path={appUrl()} element={<AppContainer />}>
      <Route path={loginUrl()} element={<Login />} />
      <Route path={appUrl()} element={<MainView />}>
        <Route path={paymentsListUrl()} element={<PaymentsList />} />

        {/* stubs */}
        <Route path={emptyStubUrl('dashboard')} element={<EmptyStub />} />
        <Route path={emptyStubUrl('payments')} element={<EmptyStub />} />
        <Route path={emptyStubUrl('users')} element={<EmptyStub />} />

        <Route
          path={emptyStubUrl('organization-payments')}
          element={<EmptyStub />}
        />
        <Route path={emptyStubUrl('payers')} element={<EmptyStub />} />
        <Route path={bankListUrl()} element={<BanksList />} />
        <Route
          path={bankEditUrl(':bankId')}
          element={<BanksCreateEditForm />}
        />
        <Route path={bankCreateUrl()} element={<BanksCreateEditForm />} />

        <Route path={emptyStubUrl('members')} element={<EmptyStub />} />
        <Route path={emptyStubUrl('user-activity')} element={<EmptyStub />} />
        <Route path={emptyStubUrl('settings')} element={<EmptyStub />} />
      </Route>
    </Route>
  );
};
