import React from 'react';

import { Box } from 'components';

import { Navigation } from '../Navigation/Navigation';
import { UserMenu } from '../Navigation/UserMenu';

import { RouterOutlet, styled } from 'third-party';

const NavigationPanel = styled(Box)`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${props => props.theme.custom.palette.borderColor};
  min-width: 13rem;
  max-width: 13rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  flex-grow: 1;
  margin: 1rem;
`;

export const MainView = () => {
  return (
    <>
      <NavigationPanel>
        <Navigation />
        <UserMenu />
      </NavigationPanel>
      <ContentWrapper>
        <RouterOutlet />
      </ContentWrapper>
    </>
  );
};
