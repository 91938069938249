import * as SwaggerAPI from './swaggerApi';
import { NonNullableObject } from './utils';

// generic
export enum APISortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type APIGenericList<ItemType> = {
  result: ItemType[];
  pageNumber: number;
  pageSize: number;
  count: number;
};

// app module
export type APIUser = Required<NonNullableObject<SwaggerAPI.APIUserModel>>;

// payments module
export type APIBank = Required<NonNullableObject<SwaggerAPI.APIBankModel>>;
export type APIBankSearchResult = APIGenericList<APIBank>;
export type APIBankSearchFilter = SwaggerAPI.APIBankSearchFilter;
