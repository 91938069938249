import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';

import { Box, IconButton, Tooltip } from 'components';

import { TableColumn } from './types';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

export const getOptionColumn = <
  ItemT extends {
    id: number | string;
    highlights?: Record<string, string[] | null> | undefined;
  },
>(
  onRowMenuClicked: (event: React.MouseEvent<HTMLElement>, item: ItemT) => void,
): TableColumn<ItemT> => ({
  dataId: 'options',
  width: 56, // px
  cannotBeHidden: true,
  notFilterable: true,
  fixed: true,
  formatter: (value: string, item: ItemT) => {
    return {
      value: '',
      element: (
        <Box id={item.id.toString()}>
          <Tooltip
            placement="top-start"
            enterDelay={TOOLTIP_APPEAR_DELAY}
            enterNextDelay={TOOLTIP_APPEAR_DELAY}
            title={'options'}
          >
            <IconButton
              style={{ padding: '0rem' }}
              color="primary"
              onClick={event => {
                onRowMenuClicked(event, item);
                event.stopPropagation();
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    };
  },
});
