import { TableColumn } from 'components/Table/types';

import { APIBank } from 'types/api';

export const bankColumns: TableColumn<APIBank>[] = [
  {
    dataId: 'id',
    label: 'id',
    minWidth: 80,
    width: 140,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'routing',
    label: 'bankList.column.routing',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'account',
    label: 'bankList.column.account',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'accountName',
    label: 'bankList.column.accountName',
    minWidth: 80,
    width: 200,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'bankName',
    label: 'bankList.column.bankName',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'companyName',
    label: 'bankList.column.companyName',
    minWidth: 80,
    width: 130,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'address',
    label: 'bankList.column.address',
    minWidth: 80,
    width: 130,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'city',
    label: 'bankList.column.city',
    minWidth: 80,
    width: 130,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'state',
    label: 'bankList.column.state',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'zip',
    label: 'bankList.column.zip',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'createdOn',
    label: 'bankList.column.createdOn',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
];
