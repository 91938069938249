import { useEffect } from 'react';

import { useNavigate } from 'third-party';

import { loginUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { isUserAuthenticated } from 'utils/auth';

export const useAuth = () => {
  const isUserLogged = isUserAuthenticated();
  const navigate = useNavigate();

  const { userInfo, setAppIsLoaded } = useApp();

  useEffect(() => {
    if (!isUserLogged) {
      setAppIsLoaded(true);
      navigate(loginUrl());
      return;
    }

    userInfo().then(it => {
      setAppIsLoaded(true);

      if (it.error) {
        navigate(loginUrl());
      }
    });
  }, [isUserLogged, navigate, setAppIsLoaded, userInfo]);
};
