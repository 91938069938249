import React from 'react';

import { Box, List, ListSubheader } from '@mui/material';
import { NavigationLink } from 'components';

import { NavigationItems } from 'types/app';

type Props = {
  subHeader: string;
  items: NavigationItems[];
};

export const NavigationMenu: React.FC<Props> = ({ subHeader, items }) => {
  return (
    <Box margin="0rem 1rem 0rem 1rem">
      <List subheader={<ListSubheader>{subHeader}</ListSubheader>}>
        {items.map((it, index) => (
          <NavigationLink key={index} item={it} />
        ))}
      </List>
    </Box>
  );
};
