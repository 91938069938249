import { useSnackbar } from 'third-party';

export const useNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();

  return {
    success: (message: string) =>
      enqueueSnackbar(message, { variant: 'success' }),
    error: (message: string) => {
      enqueueSnackbar(message, { variant: 'error' });
    },
  };
};
