import React, { useEffect, useState } from 'react';

import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';
import Logo from 'images/logo-white.svg';

import {
  styled,
  useForm,
  useNavigate,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { bankListUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { FormField } from 'components/FormField/FormField';

import { emailField } from 'utils/validations';

import { visibleForTablet } from 'styles/common';

const LoginContainer = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;

const LeftPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 40%;
  background-color: ${props => props.theme.custom.palette.primary900};

  ${visibleForTablet()}
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  min-width: 25rem;
`;

enum FormFields {
  Email = 'email',
  Password = 'password',
}

const loginFormDefaultValues = {
  [FormFields.Email]: '',
  [FormFields.Password]: '',
};

type LoginFormData = typeof loginFormDefaultValues;

export const Login = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof loginFormDefaultValues>({
    defaultValues: loginFormDefaultValues,
  });

  const navigate = useNavigate();
  const { userLogIn } = useApp();
  const { error } = useNotifications();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const user = useSelector(appSelectors.user);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(bankListUrl());
    }
  }, [navigate, user, userInfoPending]);

  const onSubmit = async ({ email, password }: LoginFormData) => {
    const result = await userLogIn(email, password);

    if (result.error) {
      error(t('login.invalidCredentials'));
      return;
    }

    navigate(bankListUrl());
  };

  return (
    <LoginContainer>
      <LeftPanel>
        <Typography
          variant="h3"
          margin="2rem"
          color={theme.custom.palette.whiteText}
        >
          <img src={Logo} height="35px" />
        </Typography>
        <Typography
          variant="subtitle1"
          margin="2rem"
          color={theme.custom.palette.whiteText}
        >
          {t('common.slogan')}
        </Typography>
      </LeftPanel>
      <RightContainer>
        <Box paddingBottom="1rem">
          <Typography variant="h2" textAlign="center">
            {t('login.header')}
          </Typography>
          <Typography variant="subtitle1">{t('login.subHeader')}</Typography>
        </Box>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormField
            fieldName={FormFields.Email}
            fieldError={errors[FormFields.Email]}
            placeholder={t('common.form.email.placeholder')}
            requiredErrorMessage={t('errors.fields.emptyFieldError')}
            control={control}
            maxLength={emailField.maxLength}
            isRequired
            showIsRequiredMark={false}
            rules={{
              validate: {
                invalidEmail: (value: string) =>
                  emailField.validate(value) ||
                  t('common.form.email.error.invalid'),
              },
            }}
          />
          <FormField
            type={showPassword ? 'text' : 'password'}
            fieldName={FormFields.Password}
            fieldError={errors[FormFields.Password]}
            placeholder={t('common.form.password.placeholder')}
            requiredErrorMessage={t('errors.fields.emptyFieldError')}
            control={control}
            maxLength={emailField.maxLength}
            isRequired
            showIsRequiredMark={false}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ padding: 0 }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            paddingTop="1rem"
            gap="1.7rem"
          >
            <Button
              type="submit"
              variant="contained"
              size="medium"
              color="primary"
            >
              {t('login.signIn')}
            </Button>
            <Divider>{t('login.continueWith')}</Divider>
            <Button variant="outlined" size="medium" color="primary">
              <PersonOutlineRoundedIcon />
              Register
            </Button>
            <Typography variant="subtitle1" textAlign="center" maxWidth="25rem">
              {t('login.terms')}
            </Typography>
          </Box>
        </Form>
      </RightContainer>
    </LoginContainer>
  );
};
