import React from 'react';

import CloseIcon from '@mui/icons-material/Close';

import { IconButton, Loader } from 'components';
import { useAuth } from 'hooks';

import {
  RouterOutlet,
  SnackbarKey,
  SnackbarProvider,
  createGlobalStyle,
  cssResetStyles,
  useSelector,
  useSnackbar,
} from 'third-party';

import { appSelectors } from 'selectors';

const GlobalStyle = createGlobalStyle`
  ${cssResetStyles}
 

  html {
    font-family: 'Inter';
  }

  #root {
    display: flex;
    height: 100%;
  }
`;

const SnackbarCloseButton = ({ snackbarKey }: { snackbarKey: SnackbarKey }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CloseIcon style={{ fill: 'white' }} />
    </IconButton>
  );
};

export const AppContainer = () => {
  const isAppLoaded = useSelector(appSelectors.isAppLoaded);

  useAuth();

  return (
    <SnackbarProvider
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      action={snackbarKey => <SnackbarCloseButton snackbarKey={snackbarKey} />}
    >
      <GlobalStyle />
      {!isAppLoaded ? <Loader /> : <RouterOutlet />}
    </SnackbarProvider>
  );
};
