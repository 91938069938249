export const APP_PATH = process.env['APP_PATH'];
export const API_PATH = process.env['API_PATH'];

export const appUrl = () => `${APP_PATH}`;

export const loginUrl = () => `${appUrl()}login`;

export const paymentsListUrl = () => `${appUrl()}payments`;
export const scheduleUrl = () => `${appUrl()}schedule`;

export const bankListUrl = () => `${appUrl()}banks/`;
export const bankEditUrl = (bankId: string) => `${bankListUrl()}${bankId}`;
export const bankCreateUrl = () => `${bankListUrl()}create`;

export const emptyStubUrl = (page: string) => `${appUrl()}${page}`;
