import { API_PATH } from './url';

export const userEndpoint = () => `${API_PATH}user/`;
export const userAuthEndpoint = () => `${userEndpoint()}auth`;
export const userInfoEndpoint = () => `${userEndpoint()}me`;

// payments module
export const bankEndpoint = (organizationId: string) =>
  `${API_PATH}/region/${organizationId}/bank/`;

export const bankSingleEndpoint = (organizationId: string, bankId: string) =>
  `${bankEndpoint(organizationId)}${bankId}/`;
