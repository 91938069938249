import React from 'react';

import { Box, NavigationMenu } from 'components';
import logo from 'images/logo-black.svg';

import { MainMenu } from './Menu';

export const Navigation = () => {
  return (
    <>
      <Box
        margin="2rem 2rem 1rem 1rem"
        alignContent="center"
        justifyContent="center"
        display="flex"
      >
        <img src={logo} height="33px" />
      </Box>
      <Box display="flex" overflow="auto" flexDirection="column" flexGrow="1">
        <Box>
          {MainMenu.map((it, index) => (
            <NavigationMenu key={index} {...it} />
          ))}
        </Box>
      </Box>
    </>
  );
};
