import React, { useCallback, useEffect, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { DialogModal, Loader, MenuItem, Table } from 'components';

import { bankColumns } from './bankColumns';

import { useNavigate, useSelector, useTranslation } from 'third-party';

import { bankCreateUrl, bankEditUrl } from 'constants/url';

import { useBank } from 'modules/bank';

import { bankSelectors } from 'selectors';

import { APIBank } from 'types/api';

export const BanksList = () => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState(bankColumns);
  const [selectedItem, setSelectedItem] = useState<string | null>(null);

  const { loadBanks, loadMoreBunks, updateBunkFilters, deleteBank } = useBank();
  const navigate = useNavigate();

  const items = useSelector(bankSelectors.bankList);
  const banksListLoaded = useSelector(bankSelectors.banksListLoaded);
  const allBunksLoaded = useSelector(bankSelectors.allBunksLoaded);
  const bunkFilters = useSelector(bankSelectors.bankFilters);
  const loadBanksPending = useSelector(bankSelectors.loadBanksPending);
  const loadMoreBunksPending = useSelector(bankSelectors.loadMoreBunksPending);

  useEffect(() => {
    loadBanks();
  }, [bunkFilters, loadBanks]);

  const onColumnFilterChange = useCallback(
    (columnId: string, value: string) => {
      setColumns(
        columns.map(column => {
          if (column.dataId === columnId) {
            return { ...column, filter: value };
          }
          return column;
        }),
      );

      updateBunkFilters({ ...bunkFilters, [columnId]: value });
    },
    [bunkFilters, columns, updateBunkFilters],
  );

  const onFilterReset = useCallback(() => {
    updateBunkFilters({});
    setColumns(it => it.map(column => ({ ...column, filter: undefined })));
  }, [updateBunkFilters]);

  const onColumnVisibilityChanged = useCallback(
    (columnId: string, value: boolean) => {
      setColumns(it =>
        it.map(column => {
          if (column.dataId === columnId) {
            return { ...column, hidden: !value };
          }
          return column;
        }),
      );
    },
    [],
  );

  const rowOptionsMenu = useCallback(
    (item: APIBank, onRowMenuClose: () => void): React.ReactElement[] => [
      <MenuItem
        key={'delete'}
        onClick={() => {
          setSelectedItem(item.id);
          onRowMenuClose();
        }}
      >
        <DeleteOutlineOutlinedIcon
          color="primary"
          style={{ paddingRight: '0.5rem' }}
        />
        {t('table.delete')}
      </MenuItem>,
      <MenuItem
        key={'edit'}
        onClick={() => {
          navigate(bankEditUrl(item.id));
          onRowMenuClose();
        }}
      >
        <EditOutlinedIcon color="primary" style={{ paddingRight: '0.5rem' }} />
        {t('table.edit')}
      </MenuItem>,
    ],
    [navigate, t],
  );

  if (!banksListLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Table<APIBank>
        header={t('bankList.header')}
        subHeader={t('bankList.subHeader')}
        filterable
        allItemsLoaded={allBunksLoaded}
        infiniteScroll
        tableMinHeight="20rem"
        columns={columns}
        items={items || []}
        rowOptionsMenu={rowOptionsMenu}
        onColumnFilterChange={onColumnFilterChange}
        onColumnVisibilityChanged={onColumnVisibilityChanged}
        loadItemsPending={loadBanksPending}
        loadMoreItemsPending={loadMoreBunksPending}
        onLoadMoreClicked={loadMoreBunks}
        onFilterReset={onFilterReset}
        bodyRowHeight="1rem"
        headerRowHeight="0.2rem"
        itemsLoaded={banksListLoaded}
        onNewItemButtonClicked={() => navigate(bankCreateUrl())}
        emptyText={t('table.empty')}
        loadMoreButtonText={t('table.loadMore')}
      />
      <DialogModal
        onClose={() => setSelectedItem(null)}
        open={!!selectedItem}
        description={t('bankList.deleteConfirm')}
        handleActionClick={() => {
          if (selectedItem) {
            deleteBank(selectedItem);
          }
          setSelectedItem(null);
        }}
      />
    </>
  );
};
