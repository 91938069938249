import React from 'react';

import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from 'components';

import { useLocation } from 'third-party';

import { NavigationItems } from 'types/app';

type Props = {
  item: NavigationItems;
};

export const NavigationLink: React.FC<Props> = ({ item }) => {
  const { pathname } = useLocation();

  return (
    <Link href={item.url?.() || '#'} underline="none">
      <ListItem disablePadding>
        <ListItemButton selected={pathname == item.url?.()}>
          {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.text} />
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
