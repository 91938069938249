import React from 'react';

import { ThemeProvider } from '@mui/system';
import { store } from 'modules';
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { useRoutes } from 'routes';

import { Provider, StyledThemeProvider, createTheme } from 'third-party';

import { themeConfig } from 'styles/theme';

// @ts-ignore
const theme = createTheme(themeConfig);

export const RootContainer = () => {
  const router = createBrowserRouter(createRoutesFromElements(useRoutes()));

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </StyledThemeProvider>
      </ThemeProvider>
    </Provider>
  );
};
