export const getUserToken = () => {
  return localStorage.getItem('token') ?? '';
};

export const saveUserToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const deleteUserToken = () => {
  localStorage.removeItem('token');
};

export const isUserAuthenticated = () => {
  const token = getUserToken();

  return !isTokenExpired(token);
};

export const decodeToken = (userToken: string) => {
  try {
    const token = userToken.replace('Bearer ', '').trim();
    if (token.split('.').length !== 3 || typeof token !== 'string') {
      return null;
    }
    const payload: string = token.split('.')[1];
    const padding: string = '='.repeat((4 - (payload.length % 4)) % 4);

    const base64: string =
      payload.replace('-', '+').replace('_', '/') + padding;

    const decoded = JSON.parse(atob(base64));

    return decoded;
  } catch {
    return null;
  }
};

export const isTokenExpired = (token: string) => {
  const decodedToken: any = decodeToken(token);

  if (decodedToken && decodedToken.exp) {
    const expirationDate: Date = new Date(0);
    expirationDate.setUTCSeconds(decodedToken.exp);
    return expirationDate < new Date();
  }

  return true;
};
