import React from 'react';

import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  PopupState,
  bindMenu,
  bindTrigger,
} from 'components';

import {
  styled,
  useNavigate,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { loginUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { deleteUserToken } from 'utils/auth';

const StyledFilterButton = styled(Button)`
  border-radius: 10rem;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  text-transform: none !important;
`;

export const UserMenu = () => {
  const theme = useTheme();
  const user = useSelector(appSelectors.user);
  const navigate = useNavigate();

  const { userLogOut } = useApp();
  const { t } = useTranslation();

  const onLogOutClick = async () => {
    await userLogOut();
    deleteUserToken();

    navigate(loginUrl());
  };

  return (
    <Box margin="1rem 1rem 1rem 2rem" display="flex" alignItems="center">
      <Avatar
        sx={{
          width: '1.6rem',
          height: '1.6rem',
          fontSize: '0.6rem',
          bgcolor: theme.custom.palette.primary800,
          textTransform: 'uppercase',
        }}
        sizes="small"
      >
        {user?.firstName[0] || ''}
        {user?.lastName[0] || ''}
      </Avatar>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <React.Fragment>
            <StyledFilterButton variant="text" {...bindTrigger(popupState)}>
              {user?.firstName} {user?.lastName}
            </StyledFilterButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  onLogOutClick();
                }}
              >
                {t('common.logout')}
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Box>
  );
};
