import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: {
      'common.delete': 'Delete',
      'common.apply': 'Apply',
      'common.reset': 'Reset',
      'common.approve': 'Approve',
      'common.loading': 'Loading ...',
      'common.loadMore': 'Load more',
      'common.send': 'Send',
      'common.slogan': 'Easy electronic check management',
      'common.logout': 'Log out',

      // empty
      'common.empty.header': 'This page is under development',
      'common.empty.subHeader': 'This is empty sub header',

      // forms
      'common.form.email.label': 'email',
      'common.form.email.placeholder': 'Enter email address',
      'common.form.email.error.invalid': 'Invalid Email Address',
      'common.form.email.notFoundText': 'No email found',

      'common.form.password.label': 'Password',
      'common.form.password.placeholder': 'Enter Password',
      'common.form.password.hint':
        '{{minLength}} characters minimum, case sensitive.',
      'common.form.save': 'Save',
      'common.form.cancel': 'Cancel',
      'common.form.deleteConfirm': 'Are you sure you want to delete this item?',
      'common.form.confirm': 'Confirm',
      'common.form.fieldRequired': 'This field is required',
      'common.form.enterValueFor': 'Enter value for ',

      // table
      'table.columnVisibility': 'Column visibility',
      'table.empty': 'There are no items to display',
      'table.loadMore': 'Load more',
      'table.delete': 'Delete',
      'table.edit': 'Edit',

      // common errors
      'errors.fields.fieldLongerThen': 'Field is longer than',
      'errors.fields.characters': 'characters',
      'errors.fields.emptyFieldError': "Field can't be empty",

      // login form
      'login.header': 'Login into account',
      'login.subHeader': 'Enter your email and password to your account',
      'login.signIn': 'Sing in',
      'login.continueWith': 'or continue with',
      'login.invalidCredentials': 'Invalid email or password',

      'login.terms':
        'By clicking continue, you agree to our Terms of Service and Privacy Policy.',

      // payments list
      'payments.header': 'Payments',
      'payments.filters.placeholder': 'filter by paid and status',
      'payments.filters.selected': 'Selected',
      'payments.filters.paidTo': 'Paid to',
      'payments.filters.status': 'Status',

      'payments.subHeader': "Here's a list of all active payments",

      //bank list
      'bankList.header': 'Bank List',
      'bankList.subHeader': "Here's a list of all active banks in the account",
      'bankList.column.id': 'Id',
      'bankList.column.account': 'Account',
      'bankList.column.routing': 'Routing',
      'bankList.column.accountName': 'Account Name',
      'bankList.column.bankName': 'Bank Name',
      'bankList.column.companyName': 'Company Name',
      'bankList.column.address': 'Address',
      'bankList.column.city': 'City',
      'bankList.column.state': 'State',
      'bankList.column.zip': 'Zip',
      'bankList.column.createdOn': 'Created On',
      'bankList.deleteConfirm': 'Are you sure you want to delete this bank?',

      // bank form
      'bankForm.create.header': 'Bank Form',
      'bankForm.create.subHeader': 'Fill in the details of the new bank',
      'bankForm.create.sucscefull': 'Bank created successfully',
      'bankForm.create.error': 'Error creating bank',
      'bankForm.edit.header': 'Edit bank',
      'bankForm.edit.sucscefull': 'Bank updated successfully',
      'bankForm.edit.subHeader': 'Edit bank details',
      'bankForm.edit.error': 'Error updating bank',
    },
  },
  uk: {
    translation: {},
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    resources,
    debug: process.env.I18N_DEBUG === 'true',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
