import React from 'react';

import { Box, Typography } from 'components';

type Props = {
  header?: string;
  subHeader?: string;
  children?: React.ReactNode;
};
export const PageHeader: React.FC<Props> = ({
  header,
  subHeader,
  children,
}) => {
  return (
    <Box zIndex={10} top={0} position="sticky" bgcolor="white">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        paddingTop="0.5rem"
      >
        <Box>
          {header && <Typography variant="h2">{header}</Typography>}
          {subHeader && (
            <Typography variant="subtitle1">{subHeader}</Typography>
          )}
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
