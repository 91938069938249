export const camelCase = (input: string) =>
  input
    .toLowerCase()
    .split(/[\s-_]+/)
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');

export const isObjectLike = (value: any): boolean => {
  return value !== null && typeof value === 'object';
};

export const isFunction = (value: any): boolean => {
  return typeof value === 'function';
};

export const keys = (obj: object): string[] => {
  if (obj === null || typeof obj !== 'object') {
    throw new TypeError('Expected an object');
  }
  return Object.keys(obj);
};

export const stringifyUrl = ({
  url,
  query,
}: {
  url: string;
  query: Record<string, any>;
}): string => {
  const queryString = Object.keys(query)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`)
    .join('&');
  return `${url}?${queryString}`;
};
